.collect {
  padding-bottom: 100px;

  .copy_text {
    top: 52px;
  }

  .help-block {
    margin-top: 18px;
    padding: 40px 50px 24px;
    background: #2a2c39;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 12px 12px 0 0;
    text-align: center;
    font-size: 14px;
    color: #ced3e1;

    > span > span {
      margin: 0 3px;
      color: #2ce1b7;
      font-size: 18px;
    }

    .help-account {
      font-family: AvenirNext;
      font-size: 16px;
      line-height: 19px;
      color: rgba(206, 211, 225, 0.8);
      margin-bottom: 11px;
    }
  }

  .copy {
    display: flex !important;
    justify-content: space-between;
    padding: 24px 18px;
    background: #333643;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 12px 12px;
    word-break: break-all;
    font-family: AvenirNext-Medium;
    font-size: 12px;
    color: #ced3e1;
    word-break: break-all;

    .copyBtn {
      margin-left: 22px;
    }
  }

  .address {
    text-align: center;
    margin: 18px 15px 14px;

    .qrcode-box {
      padding: 5px 5px 0px;
      background: #fff;
      border-radius: 10px;
      display: inline-block;
      height: 100px;
    }

    button {
      color: #fff;
      background: #2a2c39;
      border: none;
      padding: 10px 27px;
      min-width: 85px;
      border: 1px solid #80838d;
      margin-top: 20px;
      border-radius: 10px;

      a {
        color: #fff;
      }
    }
  }

  .no-login {
    text-align: center;
    padding-bottom: 38px;
    width: 94%;
    margin: 0 auto;

    img {
      margin: 33px 0 20px;
    }

    p {
      font-family: AvenirNext-Regular;
      font-size: 14px;
      color: #80838d;
      line-height: 20px;
      margin: 0 auto;
      text-align: left;

      &.tipPlease {
        font-size: 13px;
        line-height: 20px;
        margin-top: 8px;
        text-align: center;
      }

      &.tipLink {
        font-size: 13px;
        line-height: 20px;
        text-align: center;

        a {
          color: #7786ff;
          text-decoration: underline;
        }
      }
    }
  }

  .tip {
    font-family: AvenirNext-Regular;
    font-size: 12px;
    color: #80838d;
    line-height: 14px;
    padding: 0 15px;
    margin: 14px auto 0;
    text-align: center;
  }
}
