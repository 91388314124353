.wrapper-home {
  position: relative;
  margin: 20px 15px 30px;
  padding-bottom: 60px;
}

.ant-modal-root {
  .ant-modal-mask {
    background-color: rgba($color: #1b1f26, $alpha: 0.8);
  }
}

.header-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;

  .logo {
    a {
      display: inline-block;

      img {
        height: 32px;
        // width: 175px;
        // vertical-align: top;
        // margin-top: -2px;
      }
    }
  }

  .menu {
    background: rgba(63, 67, 84, 0.3);
    padding: 9px 9px 3px 9px;
    border-radius: 6px;

    .anticon {
      width: 16px;
      height: 16px;
      background: url(../images/menu.svg) no-repeat;
      background-size: 16px;

      svg {
        display: none;
      }

      &:hover {
        opacity: 0.4;
      }
    }
  }

  .token-select {
    width: 130px;
    border-radius: 16px;
    background: #3e4151;
    z-index: 990;
    opacity: 1;

    .ant-select-selection-item {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin-right: 8px;
      }
    }

    &.ant-select-focused {
      &.ant-select-open {
        outline: none;
      }
      .ant-select-selector {
        box-shadow: none !important;
      }
    }

    &:focus,
    &:active,
    &:visited,
    &:hover {
      outline: none;
    }
  }
}

.main-content {
  margin: 0 auto;
}

.account-tooltip {
  &.ant-tooltip {
    max-width: 70%;
    box-shadow: none;
    left: 50px !important;
  }

  .account-tooltip-text {
    opacity: 0.9;
    font-family: AvenirNext-Regular;
    font-size: 12px;
    color: #ced3e1;
  }

  .ant-tooltip-inner {
    border-radius: 12px;
    padding: 16px 26px;
    box-shadow: none;
  }
}

.account-list {
  .ant-select.token-select {
    width: 80%;
    margin: 0 10%;
    text-align: center;
  }

  .shield-account,
  .public-account {
    width: 100%;
    border: 1px solid rgba($color: #1b1f26, $alpha: 0.8);
    padding: 10%;
    margin-top: 10px;
  }

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    margin-bottom: 10px;

    > span {
      display: flex;
      justify-content: space-between;

      &:nth-child(1) {
        position: relative;
        left: -16px;
        display: inline-block;
        padding: 5px 10px 5px 14px;
        background: #2a2d39;
        font-size: 12px;
        color: #5cebc9;
        border-radius: 0px 16px 16px 0px;

        &.open {
          color: #fff;
        }
      }
    }
  }

  .balance {
    width: 100%;
    font-size: 20px;
    color: #ced3e1;
    text-shadow: 0px 0px 4px rgba(30, 35, 66, 0.500656);

    &.info {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
    }

    .uint {
      margin-left: 6px;
      font-size: 14px;
      color: #fff;
      opacity: 0.7;
    }
  }

  .address {
    margin-top: 4px;
    color: #80838d;
    font-size: 12px;

    .copy-link {
      position: relative;
    }
  }

  .action-btns {
    margin-top: 20px;

    a {
      display: inline-block;
      text-align: center;
      background: #4455e6;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
      border-radius: 18px;
      color: #fff;
      font-size: 16px;
      padding: 6px 34px;
      // max-width: 200px;
      margin-right: 20px;
      user-select: none;

      &:last-child {
        margin-right: 0;
      }

      &.touchover {
        background: #8996ff;
      }
    }

    .special-link {
      width: auto;
      background: none;
      font-size: 12px;
      text-decoration: underline;
      box-shadow: none;
      margin-right: 0;
      padding: 0;
      transform: translateY(10px);
    }
  }

  .long-bar {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: calc(100% + 32px);
    padding: 0 10px 0 20px;
    margin-top: 5px;
    height: 36px;
    line-height: 36px;
    transform: translate(-16px, 16px);
    background: #3e4151;
    border-radius: 12px;

    > span > div > span {
      font-size: 12px;

      &.uint {
        font-size: 12px;
      }
    }

    &.high-bar {
      height: 52px;

      .balance {
        width: 40%;
        line-height: 20px;

        > div {
          line-height: 32px;
          font-size: 12px;

          &:last-child {
            line-height: 8px;
          }
        }
      }

      .energy-band {
        display: flex;
        justify-content: space-between;
        width: 60%;
      }

      .energy,
      .bandwidth {
        width: 50%;

        // line-height: 52px;
        > p {
          height: 26px;
          line-height: 18px;
          margin-bottom: 0;
          text-align: right;
          font-size: 12px;
          color: #80838d;

          &:first-child {
            color: #ced3e1;
            line-height: 32px;
          }
        }
      }

      .energy {
        margin-right: 16px;
      }
    }

    .color-status {
      width: 3px;
      background: #1c8f74;
      position: absolute;
      left: 0;
      height: 24px;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 2px;

      &.red {
        background: #ff5859;
      }

      &.green {
        background: #2ce1b7;
      }
    }

    &.stat-process {
      color: #80838d;
      font-size: 12px;

      .stat-status {
        span {
          color: #ced3e1;
          margin-left: 6px;
        }
      }
    }

    &.other-info {
      font-size: 12px;

      .ml {
        margin-left: 10%;
      }
    }

    .red {
      color: #ff5859 !important;
    }

    .green {
      color: #2ce1b7 !important;
    }
  }

  .copyBtn {
    // transform: translateY(-2px);
    margin-left: 6px;
    width: 10px;
    height: 12px;

    &.touchover {
      opacity: 0.4;
    }
  }
}

body .ant-select-single.ant-select-open .ant-select-selection-item {
  opacity: 1;
  color: #fff;
}

body .ant-select-dropdown.token-select-list {
  width: calc(100% - 30px) !important;
  text-align: center;
  left: 15px !important;
  border-radius: 12px;
  padding: 10px 0;
  background: #383b4a;
  z-index: 980;

  .ant-select-item {
    padding: 0;

    img {
      margin-right: 8px;
    }
  }

  .ant-select-item-option-content {
    height: 40px;
    line-height: 40px;
    // background: #383b4a;
    text-align: left;
    padding-left: 10%;

    &:hover {
      background: rgba($color: #5b5f73, $alpha: 0.3);
    }

    &:visited {
      background: rgba($color: #5b5f73, $alpha: 0.3);
    }
  }
}

body .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: rgba($color: #5b5f73, $alpha: 0.3);
}

body .ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 31px;
}

.home-modal {
  width: 315px !important;

  .ant-modal-content {
    background-color: #363947;
    border-radius: 12px;
  }

  .modal-body-title {
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    color: #ffffff;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .ant-modal-body {
    padding: 30px 20px 24px;
  }

  .body-text {
    opacity: 0.7;
    font-family: AvenirNext-Regular;
    font-size: 14px;
    color: #ced3e1;
    line-height: 18px;

    a {
      color: #7786ff;
      text-decoration: underline;
    }
  }
}

.version-modal {
  .ant-modal-body {
    padding: 30px 20px 24px;
  }

  .title {
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    color: #fff;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .content {
    font-size: 15px;
    line-height: 25px;
    margin-top: 40px;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;

    button {
      display: inline-block;
      background: #4455e6;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      cursor: pointer;
      font-family: AvenirNext-Medium;
      font-size: 14px;
      color: #fff;
      line-height: 43px;
      text-align: center;
      // width: 131px;
      width: 100%;
      height: 43px;
      border: none;

      a {
        color: #fff;
        display: inline-block;
        width: 100%;
      }
    }
  }
}

.menu-dr {
  color: #fff;

  .dr-logo {
    display: block;
    width: 60px;
    margin-top: 90px;
    margin-left: 52px;
    margin-bottom: 25px;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .menu-svg {
    height: 16px;
    width: 16px;
    display: inline-block;
    margin-right: 12px;
    vertical-align: top;
    margin-top: 12px;
  }

  .menu-svg-close {
    height: 28px;
    width: 28px;
  }

  .ant-drawer-content-wrapper,
  .ant-drawer-content,
  .dr-content,
  .ant-menu,
  .ant-menu-submenu > .ant-menu,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #1b1f26;
    color: #fff;
  }

  .ant-drawer-close {
    color: #fff;
  }

  .ant-menu-item:hover,
  .ant-menu-item-active,
  .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
  .ant-menu-submenu-active,
  .ant-menu-submenu-title:hover {
    color: #ced3e199;
  }

  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left,
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }

  .menu-m {
    font-family: AvenirNext-Medium;
    font-size: 16px;
    color: #ced3e199;
    a,
    span {
      color: #ced3e199;
    }
    img {
      opacity: 0.6;
    }

    &.menu-active {
      img {
        opacity: 1;
      }
      a,
      span {
        color: #fff;
      }
    }
    &.menu-lg {
      img {
        opacity: 1;
      }
      .lg-active {
        color: #fff;
      }
    }

    .menu-s {
      a,
      span {
        color: #ced3e14d;
      }

      &.menu-active {
        img {
          opacity: 1;
        }
        a,
        span {
          color: #fff;
        }
      }
    }
  }

  // .menu-m:hover,
  // .menu-s:hover {
  //   opacity: 1;
  //   color: #fff;
  // }

  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
  .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
    background: #fff;
  }

  .dr-content {
    .ant-menu-item-active {
      a:hover {
        color: #fff;
      }
    }
    // .ant-menu-submenu-active {
    //   span {
    //     color: #fff;
    //   }
    // }
  }
}

.modal-footer {
  margin-top: 40px;
  display: flex;
  justify-content: space-around;

  .footer-btn {
    display: inline-block;
    background: rgba(223, 231, 253, 0.08);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    cursor: pointer;
    width: auto;
    font-family: AvenirNext-Medium;
    font-size: 14px;
    color: #ffffff;
    line-height: 43px;
    text-align: center;
    width: 131px;
    height: 43px;

    &:nth-child(1) {
      margin-right: 14px;
    }

    &:nth-child(2) {
      background: #4455e6;
    }

    &:hover {
      background: #7788ff;
    }

    a {
      color: #fff;
    }
  }
}

.footer {
  margin: 0 15px;

  .footer-link {
    display: flex;
    justify-content: center;

    a {
      margin: 0 9px;
      width: 16px;
      display: inline-block;
      height: 16px;

      &.telegram {
        background: url(../images/telegram.svg) no-repeat;
        background-size: 100%;
        margin-top: 2px;
      }

      &.github {
        background: url(../images/github.svg) no-repeat;
        background-size: 100%;
      }

      &.twitter {
        background: url(../images/twitter.svg) no-repeat;
        background-size: 100%;
      }
    }
  }

  .footer-copyright {
    margin: 8px 0 auto;
    text-align: center;
    opacity: 0.5;
    font-family: AvenirNext;
    font-size: 12px;
    color: #fff;
  }

  .footer-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    margin: 0 auto;

    .footer-logo {
      height: 32px;
      margin-right: 84px;
    }

    .ant-select {
      margin-right: 60px;

      .ant-select-selection-item {
        color: #ced3e1;
      }
    }
  }
}

.ant-select:focus {
  outline: none;
}

.footer-select-auto.ant-select-dropdown {
  background: transparent;
  box-shadow: none;
  // background: #434655;
  padding: 0;

  .ant-select-item {
    height: 29px;
    line-height: 29px;
    padding: 0 10px;
    text-align: left;
    background: #434655;
    border-top: none;

    &:first-child {
      border-radius: 5px 5px 0 0;
    }

    &:last-child {
      border-radius: 0 0 5px 5px;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #434655;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled).ant-select-item-option-active,
  .ant-select-item-option-active {
    background: #666979;
  }
}

.account-block {
  border-radius: 12px;
  background: #363947;
  margin-bottom: 10px;
  padding: 16px !important;
}

body .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  outline: none;
  transition: none;
  box-shadow: none;
  opacity: 1;
}

body .ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  display: none;
}

body .ant-tooltip .ant-tooltip-inner {
  box-shadow: none;
  background: rgba($color: #1b1f26, $alpha: 0.95);
  opacity: 1;
  color: rgba($color: #ced3e1, $alpha: 0.9);
}

body .ant-select-dropdown {
  box-shadow: 0 0 10000px 10000px rgba($color: #252a32, $alpha: 0.8);
}

body .ant-steps-vertical .ant-steps-item.ant-steps-item-active {
  .ant-steps-icon {
    &::after {
      content: '';
      position: absolute;
      left: 3.5px;
      top: 1px;
      color: #fff;
      font-size: 12px;
    }
  }

  &:nth-child(1) {
    .ant-steps-icon {
      &::after {
        content: '1';
      }
    }
  }

  &:nth-child(2) {
    .ant-steps-icon {
      &::after {
        content: '2';
      }
    }
  }

  &:nth-child(3) {
    .ant-steps-icon {
      &::after {
        content: '3';
      }
    }
  }
}

.token-select {
  .ant-select-arrow {
    right: 18px;
  }
}

.tac {
  text-align: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;
}

.tooltip-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../images/tooltipIcon.svg) 0 0 no-repeat;
  background-size: 100%;

  &.touchover {
    background: url(../images/tooltipIcon-hover.svg) 0 0 no-repeat;
    background-size: 100%;
  }
}

.loading {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url(../images/loading.svg) 0 0 no-repeat;
  background-size: 100%;
  animation: rotate 1s infinite linear;
  margin-right: 6px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1000px) {
  .footer {
    .footer-flex {
      width: 90%;

      .footer-category {
        width: 80%;

        .footer-logo {
          margin: 4%;
        }
      }

      .ant-select {
        margin-right: 1%;
      }
    }
  }
}

@media screen and (max-width: 360px) {
  .account-list .action-btns a {
    padding: 6px 22px;
  }
}
