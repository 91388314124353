body {
  margin: 0;
  font-family: Avenir, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* 覆盖框架样式，适用于移动端拖拽过界时 */
  background-color: #252a32 !important;
  font-size: 12px;
}

* {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

li {
  list-style: none;
}

#root {
  background: #252a32;
}

.c-2CE1B7 {
  color: #2ce1b7;
}

.btn {
  background: #80838d;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  border: none;
  min-width: 100px;
  // margin: 0 15px;
  // width: calc(50% - 30px);
  height: 43px;
  line-height: 43px;
  padding: 0;
  font-family: AvenirNext-Medium;
  font-size: 16px;
  color: #ffffff;
  text-align: center;

  &.cancel {
    background: #4455e6;
  }

  &.blue {
    background: #4455e6;

    &:hover {
      background: #7786ff;
      box-shadow: none;
    }
  }

  &.manual {
    height: 36px;
    line-height: 36px;
    padding: 0 40px;
    border-radius: 25px;
  }

  &:hover {
    box-shadow: none !important;
    border: none !important;
  }

  &::after {
    box-shadow: none !important;
    border: none !important;
  }
}

.mt30 {
  margin-top: 30px !important;
}

.ant-tooltip {
  .ant-tooltip-inner {
    font-family: AvenirNext;
    font-size: 12px;
    background: #1b1f26;
    border-radius: 12px;
    padding: 16px 20px;
    color: #ced3e1;
    opacity: 0.9;
  }

  .ant-tooltip-arrow-content {
    background: #1b1f26;
  }
}

body .ant-drawer {
  z-index: 100000;

  .ant-menu-submenu > .ant-menu {
    margin-left: 4px;

    .ant-menu-item {
      height: 1rem;
      line-height: 1rem;
      margin-bottom: 14px;
    }
  }
}
#root {
  .darkBlue header,
  .helpCenter header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 4;
  }
  .product-main {
    margin-top: 80px;
  }
  .helpCenter {
    padding-bottom: 16px;
    .helpContent {
      margin-top: 140px;
    }
  }
}

@media screen and (max-width: 1000px) {
  #root {
    .darkBlue header,
    .helpCenter header {
      padding: 0 3%;
    }
    .helpCenter {
      .helpContent {
        margin-top: 60px;
      }
    }
  }
}
