.select-auto {
  width: auto !important;
  &.ant-select-dropdown {
    background: #3e4cc3;
  }
  .ant-select-item {
    border-top: 1px solid #363947;
    &:nth-child(1) {
      border-top: none;
    }
    .ant-select-item-option-content {
      a {
        color: #ced3e1;
      }
    }
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #1e298c;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #111b7b;
  }
}
.product {
  // margin: 20px 20px 30px;
  padding-bottom: 16px;
  background: #2a2c39 url(../images/web/bigBg.svg) left 89% no-repeat;
  // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.50);
  background-size: 35%;
  position: relative;

  h3 {
    font-family: Avenir-Heavy;
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    padding: 0 2%;
    width: auto;
    display: inline-block;

    .bgBlock {
      background: #4455e6;
      height: 10px;
      margin-top: -15px;
      display: block;
      width: calc(100% + 12px);
      margin-left: -6px;
    }
  }

  h4 {
    font-family: Avenir-Book;
    font-size: 30px;
    color: #ffffff;
  }

  .minWidth {
    min-width: 1200px;
  }

  .darkBlue {
    color: #fff;

    h2,
    h3,
    h4,
    a,
    span,
    button a {
      color: #fff;
      margin-bottom: 0;
    }

    header {
      background: #363947;
      height: 80px;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
      align-items: center;
      > div {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin: 0 auto;
        height: 80px;
        align-items: center;
      }
      .right {
        display: flex;
        align-items: center;
      }

      .logo {
        // margin-left: 4%;
        a {
          display: inline-block;
          img {
            height: 32px;
            // width: 175px;
          }
        }
      }

      nav {
        // display: inline-block;
        // line-height: 80px;
        margin: 0 2vw;

        a {
          // font-size: 16px;
          // font-weight: 500;
          display: inline-block;
          font-size: 14px;
          color: #ced3e1;

          &:hover {
            color: #fff;
            height: 78px;
            line-height: 78px;
            border-bottom: 2px solid #4455e6;
          }
        }
      }

      .nav-set-lang {
        margin-left: 1vw;
        .ant-select-selector {
          background: transparent;
          border: none !important;
          border-radius: 0 !important;
          box-shadow: none !important;
          border-color: transparent !important;
          &:hover {
            box-shadow: none;
            border: none;
          }
          .ant-select-selection-item {
            // font-size: 16px;
            // font-weight: 500;
            font-size: 14px;
            color: #ced3e1;
          }
        }
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border: none !important;
          box-shadow: none !important;
        }
        .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
          border: none !important;
          box-shadow: none !important;
        }
        .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          border: none !important;
          box-shadow: none !important;
          border-color: transparent !important;
        }
        &.ant-select-single.ant-select-open .ant-select-selector .ant-select-selection-item {
          opacity: 1;
          color: #fff;
        }
      }
    }

    .product-main {
      padding: 4vw 0 3vw;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1200px;
      // width: 70%;

      img {
        vertical-align: middle;
        width: 43%;
        margin-left: 3%;
      }

      .left {
        width: 45%;
        // padding: 7% 0 0 5%;
        padding: 7% 0 0 5%;
        text-align: left;
        background: url(../images//web/kv_bg.png) left top no-repeat;
        background-size: 75%;
        position: relative;
        left: -68px;

        .main-title {
          line-height: 90px;
          font-family: Avenir-Heavy;
          // font-size: 3.8vw;
          font-size: 60px;
          color: #ffffff;
        }

        .second-title {
          font-family: Avenir-Book;
          font-size: 2vw;
          color: #ffffff;
        }

        .product-start-btn {
          background: #4455e6;
          border-radius: 1.8vw;
          border: none;
          padding: 0.9vw 2.8vw;
          font-family: Avenir-Book;
          font-size: 1.3vw;
          color: #b0b8ff;
          display: inline-block;
          margin-top: 2vw;
          &:hover {
            color: #fff;
            opacity: 1;
          }
        }
      }
    }
  }

  .divider {
    border-left: 1px solid rgba(195, 196, 205, 0.2);
    height: 13vw;
    vertical-align: middle;
  }

  .tokenlist {
    width: 100%;
    margin: 0 auto;
    ul {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        background: #2f333d;
        border-radius: 12px;
        height: 66px;
        line-height: 66px;
        padding: 0 15px;

        button {
          color: #111;
          padding: 2px 12px;
          min-width: 84px;
        }

        &.notOpen {
          opacity: 0.5;
        }
        .tokenName {
          font-family: AvenirNext;
          font-size: 16px;
          > img {
            margin-right: 16px;
          }
        }
      }
    }
  }

  .features {
    margin: 0vw auto 0;
    width: 55%;
    text-align: center;

    article {
      margin: 0 auto 20px;
      padding: 20px 15px;
      display: inline-block;
      justify-content: center;
      width: 49%;
      vertical-align: middle;
      background: #353947;
      border-radius: 10px;

      &.right {
        vertical-align: top;

        .flexBox {
          margin-left: 13%;
        }
      }

      .flexBox {
        width: 87%;
      }

      .text {
        text-align: center;
        margin-top: 2.3vw;

        h4 {
          margin-bottom: 1.5vw;
        }

        p {
          width: 18.6vw;
          margin: 0 auto;
          font-family: Avenir-Book;
          font-size: 16px;
          color: #ced3e1;
          text-align: center;
        }
      }

      img {
        width: 6.5vw;
      }
    }

    .title {
      margin-bottom: 3.5vw;
    }
    .based-network {
      padding: 30px 12px;
      background: #2f333d;
      border-radius: 12px;
      margin-bottom: 50px;
      .based-content {
        display: flex;
        justify-content: space-between;
        > p {
          text-align: left;
          width: calc(100% - 122px);
          font-size: 12px;
        }
        > img {
          width: 94px;
        }
      }
    }
  }

  .startNow {
    &.hide {
      display: none;
    }
    padding: 0;
    margin: 3vw auto 125px;
    width: 1200px;
    // width: 55%;
    text-align: center;

    .steps {
      display: flex;
      justify-content: center;
      margin-top: 3vw;
      align-items: center;

      .right {
        display: inline-block;
        width: 35%;

        img {
          width: 100%;
        }
      }

      article {
        display: inline-block;
        width: 65%;
        text-align: left;
        position: relative;
        // margin-left: 8%;
        .dropdown-content {
          font-family: HelveticaNeue;
          width: 10vw;
          font-size: 1vw;
          color: #fff;
          min-width: 300px;
          margin-top: 3.5vw;
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.5);
          z-index: 19;
          .dropdown-box {
            margin-top: 1vw;
            background: #383a48;
            border-radius: 5px;
            padding: 0 1vw;
            text-align: left;
          }
          .dropdown-item {
            clear: both;
            padding: 1vw 0;
            &.Android-download {
              border-top: 1px solid #444;
              .download-name {
                margin-left: 0.6vw;
              }
            }
            .download-name {
              margin-left: 0.5vw;
            }
            .md5 {
              color: #ccc;
              margin-top: 0.8vw;
              font-size: 0.6vw;
              max-width: 290px;
            }
          }
          .Android-download-line {
            div {
              display: inline-block;
            }
            a {
              margin-right: 0.8vw;
              font-size: 0.6vw;
              padding: 0.4vw 1vw;
              background: #4455e6;
              border-radius: 1vw;
            }
            padding-bottom: 1.5vw;
          }
          a {
            color: #fff;
          }
          .image-google {
            width: 1.6vw;
          }
          .image-android {
            width: 1.5vw;
          }
          .image-more {
            float: right;
            width: 0.7vw;
            margin-top: 0.2vw;
          }
        }
        .artTitle {
          font-family: Avenir-Heavy;
          font-size: 30px;
          color: #ffffff;
          letter-spacing: 0;
          line-height: 4.5vw;
          margin-bottom: 0.4vw;
        }

        .step {
          font-family: Avenir-Book;
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 0;
          line-height: 1.3vw;
          margin-bottom: 0.9vw;

          .mobile {
            display: inline-block;
          }
        }

        .btns {
          margin-top: 3.3vw;

          .downloadBtn {
            text-align: center;
            color: #fff;
            display: inline-block;
            padding: 0.75vw;
            width: 14vw;
            margin-right: 2vw;
            background: #4455e6;
            box-shadow: 0 0.2vw 2vw 0 rgba(12, 25, 120, 0.22);
            border-radius: 2.5vw;
            font-size: 1.4vw;
            font-family: Avenir-Heavy;
            line-height: 2vw;

            &:nth-child(2) {
              padding-left: 3.5vw;
              background: #4455e6 url(../images/web/iOS.svg) 4vw 0.6vw no-repeat;
              background-size: auto 2.1vw;
            }

            &:nth-child(1) {
              padding-left: 3.5vw;
              background: #4455e6 url(../images/web/android.svg) 2.5vw center no-repeat;
              background-size: auto 2.1vw;
              position: relative;
            }
          }
        }
      }
    }
  }

  // .footer {
  //     padding: 20px 0 0 0;
  //     background: #353947;

  //     .footer-link {
  //         display: flex;
  //         justify-content: center;

  //         a {
  //             // margin: 0 25px;
  //             // width: 30px;
  //             display: inline-block;
  //             // height: 30px;

  //             &.telegram {
  //                 background: url(../images/web/telegram.svg) no-repeat;
  //                 background-size: 100%;
  //             }

  //             &.github {
  //                 background: url(../images/web/GitHub.svg) no-repeat;
  //                 background-size: 100%;
  //             }

  //             &.twitter {
  //                 background: url(../images/twitter.svg) no-repeat;
  //                 background-size: 100%;
  //             }
  //         }
  //     }

  //     .footer-copyright {
  //         background: #252933;
  //         padding: 12px 0;
  //         color: #ced3e1;
  //         font-family: AvenirNext-Regular;
  //         font-size: 14px;
  //         color: rgba(255, 255, 255, 0.50);
  //         letter-spacing: 0;
  //         opacity: 1;
  //     }
  // }
}

@media screen and (min-width: 2400px) {
  .product .startNow .steps article .dropdown-content {
    width: 17vw;
  }
}

@media screen and (max-width: 1200px) and (min-width: 1001px) {
  .product .darkBlue header {
    padding: 0 144px;
    .logo {
      margin-left: 0;
    }
  }
}

.btn {
  display: inline-block;
  min-width: 85px;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  text-align: center;
  border-radius: 15px;
  font-size: 12px;
  color: #fff;
  &.trans-btn {
    background: #4455e6;
  }
  &.not-open-btn {
    color: #ced3e1;
    border: 1px solid rgba(91, 95, 115, 0.5);
    background: none;
  }
}
.manual {
  background: #4455e6 !important;
  cursor: pointer;
  a {
    font-size: 16px;
    color: #b0b8ff;
  }
  &:hover {
    a {
      color: #fff;
    }
  }
}
.feature-power {
  height: 604px;
  background: url(../images/powerBg.png) center 43px no-repeat;
  background-size: 2593px;
  .power-width {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    position: relative;
    > section {
      // width: 25%;
      position: absolute;
      top: 351px;
      left: 3px;
      .power-title {
        width: 195px;
        font-size: 24px;
        font-weight: 400;
        color: #fff;
        line-height: 32px;
      }
      .power-content {
        width: 244px;
        font-size: 14px;
        font-weight: 400;
        color: #ced3e1;
        opacity: 0.6;
        margin-top: 8px;
      }
      &:nth-child(2) {
        top: 252px;
        left: 326px;
        .power-title {
          width: 286px;
        }
        .power-content {
          width: 260px;
        }
      }
      &:nth-child(3) {
        top: 368px;
        left: 690px;
        .power-title {
          width: 195px;
        }
        .power-content {
          width: 260px;
        }
      }
      &:nth-child(4) {
        top: 232px;
        left: 1008px;
        .power-title {
          width: 195px;
        }
        .power-content {
          width: 175px;
        }
      }
    }
  }
}
.tron-power {
  width: 1200px;
  height: 312px;
  margin: -20px auto 0;
  background: url(../images/tronBg.svg) 0 0 no-repeat;
  background-size: 100%;
  position: relative;
  .tron-power-content {
    width: 500px;
    position: absolute;
    top: 32px;
    left: 142px;
    .title {
      font-size: 30px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      margin-top: 30px;
    }
  }
  img {
    position: absolute;
    top: 50px;
    right: 150px;
  }
}
.product {
  .feature-faq {
    width: 1200px;
    margin-top: 100px;
    margin-bottom: 80px;
    > article {
      width: 100%;
      padding: 14px 30px;
      .text {
        text-align: left;
        margin-top: 0;
        h4 {
          font-size: 20px;
          font-weight: 800;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}

.mt30 {
  margin-top: 30px !important;
}

@media screen and (max-width: 1200px) {
  .product .minWidth {
    min-width: 90%;
  }
  .product .darkBlue .product-main,
  .product .startNow,
  .tron-power,
  .feature-power .power-width,
  .product .feature-faq {
    width: 90%;
  }
  .feature-power {
    height: 500px;
    background-size: 1900px;
    .power-width {
      > section {
        // width: 25%;
        position: absolute;
        top: 250px;
        left: 60px;
        &:nth-child(2) {
          top: 180px;
          left: 280px;
        }
        &:nth-child(3) {
          top: 267px;
          left: 536px;
        }
        &:nth-child(4) {
          top: 170px;
          left: 780px;
        }
      }
    }
  }
  .tron-power {
    .tron-power-content {
      width: 52%;
      left: 8%;
      .manual {
        margin-top: 0 !important;
      }
      p {
        margin-top: 10px;
        font-size: 12px;
      }
    }
    img {
      width: 25%;
      right: 14%;
    }
  }
}

@media screen and (max-width: 1000px) {
  .product {
    background: #252a32;
    // padding-bottom: 40px;
    padding: 20px 20px 30px;

    h3 {
      font-size: 20px;

      .bgBlock {
        height: 5px;
        margin-top: -12px;
      }
    }

    h4 {
      font-size: 20px;
    }

    .minWidth {
      min-width: auto;
    }

    .darkBlue {
      header .right {
        display: none;
      }

      .product-main {
        display: block;
        width: 70%;
        padding: 40px 0 20px;

        .left {
          width: 100%;
          text-align: center;
          padding: 0;
          left: 0;
          .main-title {
            line-height: 62px;
            font-size: 40px;
          }

          .second-title {
            margin: 15px 0 40px;
            font-size: 20px;
          }

          .product-start-btn {
            display: inline-block;
            padding: 12px 25px;
            border-radius: 36px;
            font-size: 16px;
            margin-top: 0px;
          }
        }

        img {
          width: 100%;
          display: block;
          margin: 40px auto 20px;
        }
      }
    }

    .features {
      padding: 20px 0;
      width: auto;

      .title {
        margin-bottom: 40px;
      }

      article {
        display: block;
        width: auto;
        // margin: 0px auto 40px;
        &.common-use {
          padding: 15px;
          .text {
            margin-top: 0;
            h4 {
              font-size: 18px;
            }
            p {
              font-size: 12px;
            }
          }
        }
        .flexBox {
          width: 100%;
        }

        .text {
          width: 100%;
          margin-top: 18px;

          h4 {
            margin-bottom: 10px;
          }

          p {
            width: 90%;
            font-size: 12px;
          }
        }

        img {
          display: block;
          margin: 0 auto;
          margin-bottom: 20px;
          width: 25%;

          &.imgAdjust {
            margin-left: 40%;
          }
        }

        &.right {
          .flexBox {
            margin-left: 0;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .divider {
      display: none;
    }

    .startNow {
      padding: 15px 5%;
      width: 100%;
      margin: 20px 0 0 0;

      .title {
        margin-bottom: 0;
      }

      .steps {
        margin-top: 20px;
        display: block;

        article {
          display: block;
          width: 100%;
          margin-left: auto;
          .dropdown-content {
            width: 98%;
            font-size: 14px;
            margin-top: 37px;
            .dropdown-box {
              margin-top: 15px;
              padding: 0 15px;
              background: rgba($color: #383a48, $alpha: 0.97);
            }
            .dropdown-item {
              clear: both;
              padding: 15px 0;
              &.Android-download {
                border-top: 1px solid #444;
                .download-name {
                  margin-left: 15px;
                }
              }
              .download-name {
                margin-left: 10px;
              }
              .md5 {
                color: #ccc;
                margin-top: 10px;
                font-size: 12px;
                max-width: 290px;
              }
            }
            .Android-download-line {
              div {
                display: inline-block;
              }
              a {
                margin-right: 10px;
                font-size: 12px;
                padding: 5px 12px;
                background: #4455e6;
                border-radius: 15px;
              }
              padding-bottom: 20px;
            }
            a {
              color: #fff;
            }
            .image-google {
              width: 30px;
            }
            .image-android {
              width: 25px;
            }
            .image-more {
              float: right;
              width: 10px;
              margin-top: 5px;
            }
          }
          .artTitle {
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 10px;
            margin-top: 30px;
          }

          .step {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 5px;

            .mobile {
              display: inline;
            }
          }

          .btns {
            margin-top: 20px;

            .downloadBtn {
              display: inline-block;
              width: 44%;
              padding: 6px 0;
              margin-right: 10%;
              border-radius: 37px;
              font-size: 16px;
              line-height: 25px;

              &:nth-child(2) {
                margin-right: 0;
                padding-left: 10%;
                background: #4455e6 url(../images/web/iOS.svg) 32% 5px no-repeat;
                background-size: 20px;
              }

              &:nth-child(1) {
                padding-left: 10%;
                background: #4455e6 url(../images/web/android.svg) 21% center no-repeat;
                background-size: 20px;
              }
            }
          }

          &:nth-child(2) .step {
            margin-left: 0;
            margin-top: 20px;
          }
        }

        .right {
          width: 100%;
          text-align: left;
          margin-top: 20px;

          img {
            width: 100%;
          }
        }
      }
    }

    .footer {
      border-top: none;
      padding: 6px 0 6px 0px;

      .footer-link {
        display: flex;
        justify-content: center;

        a {
          margin: 0 9px;
          width: 20px;
          display: inline-block;
          height: 20px;
          &.telegram {
            background: url(../images/web/telegram.svg) no-repeat;
            background-size: 100% 100%;
          }

          &.github {
            background: url(../images/web/GitHub.svg) no-repeat;
            background-size: 100% 100%;
          }

          &.twitter {
            background: url(../images/twitter.svg) no-repeat;
            background-size: 100% 100%;
          }
        }
      }
    }

    .tron-power {
      height: 230px;
      background-size: cover;
      .tron-power-content {
        top: 7%;
      }
      h3 {
        font-size: 18px;
      }
    }
    .feature-power {
      background-size: 157%;
      .power-width > section {
        .power-title {
          font-size: 15px;
        }
        .power-content {
          font-size: 13px;
        }
        top: 64%;
        left: -5%;
        &:nth-child(2) {
          left: 25%;
          top: 47%;
        }
        &:nth-child(3) {
          left: 58%;
          top: 68%;
        }
        &:nth-child(4) {
          left: 89%;
          top: 42%;
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .product {
    .tron-power {
      height: 230px;
      background-size: cover;
      .tron-power-content {
        top: 7%;
      }
      h3 {
        font-size: 18px;
      }
      img {
        width: 30%;
        right: 3%;
        top: 20%;
      }
    }
    .feature-power {
      .power-width > section {
        .power-title {
          font-size: 14px;
        }
        .power-content {
          font-size: 12px;
        }
        top: 51%;
        &:nth-child(2) {
          top: 37%;
        }
        &:nth-child(3) {
          top: 55%;
        }
        &:nth-child(4) {
          top: 33%;
        }
      }
    }
  }
}
