.no-service-modal {
  .ant-modal {
    width: 460px !important;
    margin: auto;
    top: calc(50vh - 200px);
    .ant-modal-content {
      border-radius: 6px;
      overflow: hidden;
      .ant-modal-body {
        padding-bottom: 0px !important;
        padding: 0 24px;
        border-radius: 6px;
        overflow: hidden;
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      width: 60px;
      height: 60px;
      background: url(./warn.svg) no-repeat center/contain;
      margin: 40px auto 30px;
    }
    .text {
      color: #101010;
      text-align: center;
      font-size: 16px;
      line-height: 26px;
    }
    .btn {
      align-self: stretch;
      margin: 40px auto 40px;
      width: 360px;
      text-align: center;
      line-height: 40px;
      border-radius: 6px;
      font-size: 14px;
      color: #fff;
      cursor: pointer;
      height: 40px;
    }
  }
  &.dark {
    .ant-modal {
      .ant-modal-content {
        background-color: #151f22;
        .ant-modal-body {
          background-color: #151f22;
        }
      }
    }
    .content {
      .icon {
        background-image: url(./wran-dark.svg);
      }
      .text {
        color: #fff;
        & + .text {
          margin-top: 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .no-service-modal {
    .ant-modal {
      top: 100px;
      width: calc(100% - 40px) !important;
    }
    .content {
      .btn {
        width: 305px;
        max-width: 100%;
        margin: 30px auto 32px;
      }
    }
  }
}
