.helpCenter {
  // margin: 20px 20px 30px;
  padding-bottom: 100px;
  background: #2a2c39;
  // box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
  background-size: 20%;
  position: relative;
  min-height: 100vh;
  padding-bottom: 100px;
  .minWidth {
    min-width: 1000px;
  }
  .right {
    display: flex;
    align-items: center;
  }
  header {
    color: #fff;
    display: flex;
    justify-content: space-between;
    // padding: 0 20%;
    background: #363947;
    height: 80px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    align-items: center;
    > div {
      display: flex;
      justify-content: space-between;
      width: 1200px;
      margin: 0 auto;
      height: 80px;
      align-items: center;
    }
    .logo {
      width: 126px;
      // margin-left: 4%;
      a {
        display: inline-block;
        img {
          height: 32px;
          // width: 175px;
        }
      }
    }
    .link-a {
      color: #ced3e1;
    }
    .link-a:hover {
      color: #fff;
    }
    nav {
      // display: inline-block;
      // line-height: 80px;
      margin: 0 2vw;
      a {
        // font-size: 16px;
        // font-weight: 500;
        display: inline-block;
        font-size: 14px;
        color: #ced3e1;
        &:hover {
          color: #fff;
          height: 78px;
          line-height: 78px;
          border-bottom: 2px solid #4455e6;
        }
      }
    }
    .nav-set-lang {
      margin-left: 1vw;
      .ant-select-selector {
        background: transparent;
        border: none !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        border-color: transparent !important;
        &:hover {
          box-shadow: none;
          border: none;
        }
        .ant-select-selection-item {
          // font-size: 16px;
          // font-weight: 500;
          font-size: 14px;
          color: #ced3e1;
        }
      }
      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
      }
      .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
      }
      .ant-select-focused.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        border: none !important;
        box-shadow: none !important;
        border-color: transparent !important;
      }
      &.ant-select-single.ant-select-open .ant-select-selector .ant-select-selection-item {
        opacity: 1;
        color: #fff;
      }
    }
  }
  .topImg {
    width: 20%;
    position: absolute;
    top: 10%;
    right: 15%;
    opacity: 0.3;
  }
  .bottomImg {
    width: 20%;
    position: absolute;
    bottom: 400px;
    left: 15%;
    opacity: 0.1;
    &.createPage {
      bottom: 200px;
    }
  }
  .helpContent {
    width: 1200px;
    position: relative;
    z-index: 3;
    h2,
    h3,
    h4,
    a,
    span,
    button a,
    p {
      color: #fff;
      margin-bottom: 0;
    }
    // width: 50%;
    margin: 60px auto 125px;
    &.createGuideH4 {
      .manual {
        h4 {
          font-size: 15px;
          display: inline-block;
          width: calc(100% - 20px);
        }
        li {
          margin-top: 20px;
          &.single {
            margin-left: 1em;
            margin-bottom: -15px;
          }
          &:before {
            margin-top: 7px;
          }
        }
        img {
          margin: 16px 1em 4px;
          &.img0 {
            width: 50%;
          }
          &.img1 {
            width: 50%;
          }
        }
      }
    }
    .title {
      font-family: HelveticaNeue-Medium;
      font-size: 26px;
      color: #ffffff;
      letter-spacing: 0;
    }
    .manual {
      margin-top: 20px;
      border-top: 30px solid rgba(255, 255, 255, 0.1);
      border-radius: 12px;
      background: #363947 !important;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
      padding: 30px 40px;
      // background: #363947 url(../images/web/kv_bg.png) left bottom no-repeat;
      background-size: 20%;
      li {
        list-style: none;
        margin-top: 24px;
        &.single {
          margin-top: 3px;
          &:before {
            width: 0;
          }
          p {
            margin-top: -23px;
          }
        }
        &:first-child {
          margin-top: 0;
        }
        &:before {
          content: ' ';
          width: 6px;
          height: 6px;
          background: #80838d;
          border-radius: 6px;
          display: inline-block;
          margin-right: 8px;
          vertical-align: top;
          margin-top: 8px;
        }
      }
      h4 {
        display: inline-block;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 3px;
        opacity: 0.8;
        font-family: PingFangSC-Regular;
        color: #ced3e1;
        text-shadow: 0 0 4px rgba(30, 35, 66, 0.5);
      }
      p {
        font-size: 14px;
        color: #80838d;
        margin-left: 1em;
        opacity: 0.9;
        font-family: PingFangSC-Regular;
        &.inline {
          margin-top: -22px;
        }
        &.Note {
          opacity: 0.3;
          font-family: AvenirNext-Regular;
          font-size: 14px;
          color: #c9cedc;
          margin-top: 8px;
        }
        &.topNote {
          opacity: 0.9;
          font-family: PingFangSC-Regular;
          font-size: 14px;
          color: #80838d;
          margin-left: 0;
        }
        &.purple {
          font-family: PingFangSC-Regular;
          font-size: 12px;
          color: #7786ff;
          margin-top: 15px;
        }
        span {
          color: #80838d;
          span {
            width: 13px;
            display: inline-block;
            text-align: center;
            margin-right: 5px;
          }
        }
      }
      img {
        margin: 1em;
        margin-bottom: 0;
        &.img0 {
          width: 50%;
        }
        &.img1 {
          width: 25%;
        }
        &.img2 {
          width: 100%;
        }
        &.img3 {
          width: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .helpCenter {
    background: #252a32;
    margin: 20px 20px 30px;
    .minWidth {
      min-width: auto;
    }
    .logo {
      margin-left: 4%;
    }
    header {
      padding: 0;
      .right {
        display: none;
      }
    }
    .helpContent {
      width: 90%;
      margin: 20px auto 30px;
      background: #2a2c39;
      &.createGuideH4 {
        .manual {
          h4 {
            font-size: 14px;
            display: inline-block;
          }
          li {
            margin-top: 15px;
            &.single {
              margin: 0px;
            }
            &:before {
              margin-top: 7px;
            }
          }
          img {
            margin: 15px 1em 5px;
            &.img0 {
              width: calc(100% - 28px);
            }
            &.img1 {
              width: calc(100% - 28px);
            }
          }
        }
      }
      .title {
        background: #363947;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        padding: 20px;
        &.fz18 {
          font-size: 18px;
        }
        &.fz24 {
          font-size: 24px;
        }
      }
      .manual {
        border-top: none;
        padding: 20px;
        background: none;
        margin-top: 0px;
        border-radius: 0 0 12px 12px;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.2);
        margin-top: -10px;
        padding-top: 30px;
        h4 {
          display: inline;
        }
        img {
          width: calc(100% - 28px);
          margin: 1em;
          margin-bottom: 0;
          &.img0 {
            width: calc(100% - 28px);
          }
          &.img1 {
            width: calc(100% - 28px);
          }
          &.img2 {
            width: calc(100% - 28px);
          }
          &.img3 {
            width: calc(100% - 28px);
          }
        }
        li {
          margin-top: 20px;
          p:nth-child(2) {
            margin-top: 4px;
          }
          &.single {
            p {
              margin-top: -23px;
              &.mt10 {
                margin-top: -15px;
              }
            }
          }
        }
      }
    }
  }
}
