@keyframes leftToRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 50%;
  }
}

@-webkit-keyframes leftToRight {
  0% {
    margin-left: 0;
  }

  100% {
    margin-left: 50%;
  }
}

@keyframes rightToLeft {
  0% {
    margin-left: 50%;
  }

  100% {
    margin-left: 0%;
  }
}

@-webkit-keyframes rightToLeft {
  0% {
    margin-left: 50%;
  }

  100% {
    margin-left: 0%;
  }
}

.transfer-component {
  margin: 0 15px 30px;
  padding-bottom: 100px;

  .transfer-animate-block {
    position: absolute;
    width: calc(100% - 30px);
    left: 0;
    height: 46px;
    z-index: 1;
    margin-left: 15px;
    top: 0;
    background: #2a2c39;
    border-radius: 12px 12px 0 0;

    .active-block {
      background: rgba($color: #4455e6, $alpha: 0.8);
      width: 50%;
      height: 46px;
      border-radius: 12px;
    }

    &.tabAni1 {
      .active-block {
        animation: rightToLeft 0.3s forwards;
        -webkit-animation: rightToLeft 0.3s forwards;
      }
    }

    &.tabAni2 {
      .active-block {
        animation: leftToRight 0.3s forwards;
        -webkit-animation: leftToRight 0.3s forwards;
      }
    }

    &.hide {
      display: none;
    }
  }
}

.trans-confirm-modal .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  left: 7px;
}

.trans-confirm-modal .ant-steps-vertical {
  .ant-steps-item-icon {
    text-align: left;
  }

  .ant-steps-item-title {
    margin-top: 3px;
  }
}

.trans-confirm-modal .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 0 !important;
  background-color: transparent !important;
  border-right: 1px dashed #5b5f73 !important;
  margin-top: -5px !important;
}

.trans-confirm-modal
  .ant-steps-vertical
  > .ant-steps-item-active
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  border-right: 1px dashed #4455e6 !important;
}

.transfer-summary-err {
  font-family: AvenirNext-Regular;
  font-size: 13px;
  color: #ff5859;
  line-height: 15px;
  margin: 0 20px;
  padding: 24px 0;
}

.transfer-component {
  .title {
    position: relative;
    display: flex;
    justify-content: center;
    height: 25px;
    margin-top: 24px;
    line-height: 22px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;

    a {
      position: absolute;
      left: 5px;
      display: inline-block;
      font-size: 16px;
      color: #e0e4f1;
      font-weight: normal;
      width: 70px;
      height: 20px;
      line-height: 20px;
      display: block;
      background: url(../images/arrowLeft.svg) 1px center no-repeat;
      text-indent: 18px;

      &:hover,
      &:visited {
        opacity: 0.4;
      }
    }
  }

  .transfer-btns {
    margin: 0 auto 30px auto;
  }
}

#transfer-tab {
  display: flex;
  justify-content: center;
  margin-top: 17px;
  position: relative;

  .ant-tabs-nav {
    margin: 0 15px;
    margin-bottom: 0;
    background: #2a2c39;
    border-radius: 12px 12px 0 0;
    z-index: 3;

    .ant-tabs-tab {
      height: 46px;
      line-height: 46px;
      font-family: AvenirNext-DemiBold;
      font-size: 16px;
      color: #80838d;
      margin: 0;
      width: 50%;
      justify-content: center;
      padding: 0;
      z-index: 9;

      &:nth-child(1) {
        background: rgba($color: #4455e6, $alpha: 0.8);
      }

      &.ant-tabs-tab-active {
        border-radius: 12px;
        font-family: AvenirNext-DemiBold;
        font-size: 16px;
        color: #ffffff;
        padding: 0;
      }
    }

    .ant-tabs-ink-bar {
      display: none;
    }
  }

  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-nav-list {
    width: 100%;
  }
}

#transfer-body {
  margin: 8px 0 20px;
  background: #2a2c39;
  padding: 24px 15px 0;
  border-radius: 12px;

  .ant-select {
    border: 1px solid rgba(91, 95, 115, 0.3);
    border-radius: 10px;
  }

  .title {
    font-family: AvenirNext-Regular;
    font-size: 16px;
    color: #ced3e1;
    height: 22px;
    line-height: 22px;
    margin: 20px 0 8px;
    padding-left: 24px;
    justify-content: left;
    font-weight: normal;

    &:nth-child(1) {
      margin-top: 0;
    }

    &.from {
      background: url(../images/from.svg) left center no-repeat;
      background-size: 14px;
    }

    &.to {
      background: url(../images/to.svg) left center no-repeat;
      background-size: 14px;
    }

    &.token {
      background: url(../images/token.svg) left center no-repeat;
      background-size: 14px;
    }

    &.amount {
      background: url(../images/amount.svg) left center no-repeat;
      background-size: 14px;
    }

    &.fee {
      background: url(../images/fee.svg) left center no-repeat;
      background-size: 14px;
    }
  }

  .ant-select-selection-placeholder {
    opacity: 0.3;
    font-family: AvenirNext-Regular;
    font-size: 14px;
    color: #ced3e1;
    background: transparent;
    border-radius: 10px;
    line-height: 47px;
  }

  .qrcode {
    img {
      width: 15px;
      height: 14px;
    }
  }

  .select {
    width: 100%;
    background: transparent;

    .ant-select-selector {
      background: transparent;
      border: 1px solid rgba(91, 95, 115, 0.3);
      border-radius: 10px;
      height: 47px;
      line-height: 47px;
      padding: 0 15px;
      box-shadow: none;

      .ant-select-selection-item {
        line-height: 47px;
        color: #fff;
        padding-right: 25px;
      }
    }

    /* 下拉框箭头 */
    .ant-select-arrow {
      right: 20px;

      .anticon {
        width: 15px;
        height: 9px;
        background: url(../images/down-arrow.svg) no-repeat;
        background-size: 100% 100%;

        svg {
          display: none;
        }
      }
    }

    &.redBorder {
      border: 1px solid #ff5859 !important;
    }
  }

  .receive {
    border: 1px solid rgba(91, 95, 115, 0.3);
    border-radius: 10px;

    &.redBorder {
      border: 1px solid #ff5859 !important;
    }

    .ant-input-group-addon {
      border: none;
      background: transparent;
      padding: 0 14px 0 17px;
    }

    input {
      border: none;
      height: 47px;
      line-height: 47px;
      border-radius: 10px 0 0 10px;
      border-right: none;
      padding: 0 0 0 15px;
      color: #fff;
      opacity: 1;
      box-shadow: none;

      &:hover {
        border: none;
        box-shadow: none;
      }
    }

    &:hover {
      border-radius: 10px;
    }

    .clear {
      background: #444653;
      width: 20px;
      height: 20px;
      display: inline-block;
      border-radius: 20px;
      font-size: 16px;
      line-height: 20px;
      margin: 1px 0px 0 -3px;
    }
  }

  .ant-input-affix-wrapper {
    background: transparent;
    border: 1px solid rgba(91, 95, 115, 0.3);
    border-radius: 10px;
    height: 47px;
    line-height: 47px;
    padding: 0 15px;

    input {
      color: #fff;
    }
  }

  input {
    background: transparent;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.3;
      /* Firefox */
      font-family: AvenirNext-Regular;
      font-size: 14px;
      color: #ced3e1;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      opacity: 0.3;
      font-family: AvenirNext-Regular;
      font-size: 14px;
      color: #ced3e1;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      opacity: 0.3;
      font-family: AvenirNext-Regular;
      font-size: 14px;
      color: #ced3e1;
    }
  }

  /* 转账须知 */
  .ant-card {
    background: #333643;
    box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.16);
    border-radius: 0 0 12px 12px;
    border: none;
    margin-top: 16px;
    padding: 12px 15px;
    width: calc(100% + 30px);
    margin-left: -15px;

    .ant-card-body {
      padding: 0;
      font-family: AvenirNext-Regular;
      font-size: 12px;
      color: #80838d;
      line-height: 14px;

      .ant-card-title {
        opacity: 0.9;
        font-family: AvenirNext-Regular;
        font-size: 13px;
        color: #ced3e1;
        line-height: 18px;
        margin-bottom: 2px;
      }

      li {
        line-height: 14px;
        font-size: 12px;
        margin-top: 2px;
        text-indent: -10px;
        margin-left: 10px;

        &:before {
          content: ' ';
          width: 4px;
          height: 4px;
          margin-right: 6px;
          background: #80838d;
          display: inline-block;
          border-radius: 4px;
          vertical-align: top;
          margin-top: 5px;
        }
      }
    }
  }

  .amountInput {
    border: 1px solid rgba(91, 95, 115, 0.3);
    padding-right: 0;
    border-radius: 10px;
    margin-top: 10px;

    &.redBorder {
      border: 1px solid #ff5859 !important;
    }

    &:hover {
      border-radius: 10px;
      box-shadow: none;
    }

    input {
      border: none;
      outline: none;
      height: 47px;
      padding: 0 15px;
      line-height: 47px;
      border-radius: 10px 0 0 10px;
      color: #fff;
      opacity: 1;

      &:hover {
        border-right: none !important;
        box-shadow: none;
      }
    }

    .ant-input-group-addon {
      padding: 0 17px;
      font-family: AvenirNext-Regular;
      font-size: 14px;
      color: #fff;
      background: #4455e6;
      border-radius: 9px;
      border: none;

      &:hover,
      &:visited {
        background: #8996ff;
      }
    }
  }

  .account_general {
    opacity: 0.8;
    font-family: AvenirNext-Regular;
    font-size: 12px;
    color: #80838d;
    line-height: 16px;
    clear: both;
    margin-top: 2px;

    .right {
      float: right;
      font-family: AvenirNext-Regular;
      font-size: 12px;
      color: #80838d;
      text-align: right;
    }
  }

  .fee_general {
    opacity: 0.4;
    font-family: AvenirNext-Regular;
    font-size: 13px;
    color: #ced3e1;
    line-height: 18px;
    clear: both;
    margin-top: 2px;

    .right {
      float: right;
      opacity: 0.7;
      font-family: AvenirNext-Regular;
      font-size: 13px;
      color: #ced3e1;
    }
  }

  .errText {
    font-family: AvenirNext-Regular;
    font-size: 13px;
    color: #ff5859;
    line-height: 15px;
    margin-top: 8px;
  }
}

.trans-result-modal {
  .ant-modal-content {
    background-color: #363947;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    border-radius: 12px;
  }

  .ant-modal-body {
    background-color: #363947;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    border-radius: 12px;
  }

  .modal-title {
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    color: #ffffff;
    line-height: 20px;
    margin-bottom: 18px;
  }

  .modal-text {
    font-family: AvenirNext-Regular;
    font-size: 14px;
    color: #fff;
    line-height: 18px;
    text-align: center;
  }

  .modal-txid {
    width: 90%;
    // width: 238px;
    margin: 10px auto 26px;
    color: #7b7f8a;
    font-size: 12px;

    .modal-error-msg {
      color: #ff5859;
    }

    div {
      display: inline-block;
      word-break: break-all;
    }
  }

  .ant-btn-block {
    background: rgba(223, 231, 253, 0.08);
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    padding-top: 13px;
    padding-bottom: 13px;
    height: auto;
    border: none;
  }

  .cancelBtn {
    &:hover {
      border: none;
      background: #4455e6;
      box-shadow: none;
    }
  }
}

.trans-confirm-modal {
  box-shadow: none;
  max-width: 100%;
  margin: 0;

  .ant-modal-content {
    background-color: #363947;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    // border-radius: 12px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    box-shadow: none;
  }

  .ant-modal-body {
    background-color: #363947;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 0;
    box-shadow: none;
  }

  .modal-title {
    font-family: AvenirNext-DemiBold;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    border-bottom: 1px solid rgba(27, 31, 38, 0.4);
  }

  .modal-content {
    padding: 20px;
  }

  .balance-text {
    font-family: AvenirNext-Bold;
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    margin-bottom: 10px;
  }

  .confirm-item {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-family: AvenirNext-Regular;

    .c-fff {
      color: #fff;
      opacity: 1;
    }

    span {
      opacity: 0.4;
      font-family: AvenirNext-Regular;
      font-size: 13px;
      color: #ced3e1;

      &.toAccount {
        opacity: 0.7;
        font-family: AvenirNext-Regular;
        font-size: 13px;
        color: #ced3e1;
      }

      &.receive-amnt-no {
        font-family: AvenirNext-Medium;
        font-size: 14px;
        color: #ffffff;
        opacity: 1;
      }

      &.cost {
        opacity: 0.7;
        font-family: AvenirNext-Medium;
        font-size: 13px;
        color: #ced3e1;
      }
    }
  }

  .receive-acnt {
    margin-bottom: 8px;
  }

  .receive-energy {
    margin-bottom: 20px;
  }

  .trans-fee {
    margin-bottom: 6px;
    font-family: AvenirNext-Regular;
    font-size: 13px;
    color: #ced3e1;
    opacity: 0.7;
  }

  .receive-energy,
  .receive-bandwidth {
    opacity: 0.7;
    font-family: AvenirNext-Medium;
    font-size: 13px;
    color: #ced3e1;
  }

  .ant-btn-block {
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    border-radius: 12px;
    border: none;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  }

  .ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
    width: 1px;
    height: 20px;
  }

  .ant-steps-item-tail {
    &:nth-child(2)::after {
      background-color: #4455e6;
    }
  }

  .ant-steps-item-title {
    font-family: AvenirNext-Medium;
    font-size: 14px;
    color: #7b7f8a;
  }
}

.trans-steps {
  .step-ico {
    width: 14px;
    height: 14px;
    opacity: 0.1;
    border: 1px solid #ffffff;
    border-radius: 2px;
  }

  .step-ico-active {
    background: #4455e6;
    box-shadow: 0 2px 20px 0 rgba(68, 85, 230, 0.2);
    opacity: 1;
  }
}

.ant-select-dropdown {
  &.selectBox {
    background-color: #383b4b;
    border-radius: 10px;
    padding: 10px 0;

    .ant-select-item {
      padding: 5px 26px 5px 38px;

      &.privacyAddress {
        background: #383b4b url(../images/shield.svg) 17px center no-repeat;
      }

      &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        color: #fff;
        background: rgba(91, 95, 115, 0.3);

        &.privacyAddress {
          background: rgba(91, 95, 115, 0.3) url(../images/shield.svg) 17px center no-repeat;
        }
      }

      .tipContent {
        white-space: normal;
        opacity: 0.5;
        font-family: AvenirNext-Regular;
        font-size: 12px;
        color: #ced3e1;
      }
    }
  }
}

.transfer-component,
.trans-confirm-modal,
.trans-result-modal {
  .sendBtn {
    background: #80838d;
    height: 43px;
    line-height: 43px;
    padding: 0;
    border-radius: 10px;
    font-size: 16px;
    box-shadow: none;
    border: none;

    &.blue {
      background: #4455e6;

      &:hover {
        background: #7786ff;
      }
    }

    &:hover {
      background: #80838d;
    }
  }
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 50px);
  }

  .ant-modal.trans-confirm-modal {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding-bottom: 0;
    top: auto;
    bottom: 0;
    position: absolute;

    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      color: #7b7f8a;
    }

    .balance-text {
      font-weight: 500;
    }

    &.active {
      animation: fade-in-bottom 0.3s;
    }

    @keyframes fade-in-bottom {
      0% {
        transform: translateY(50%);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  .trans-result-modal {
    .modal-title {
      text-align: center;
    }
  }
}
