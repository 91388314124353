.records {
  margin: 24px 0 26px;
  color: #333;

  .title {
    font-family: AvenirNext-Medium;
    font-size: 16px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    line-height: 1rem;

    .tooltip-icon {
      margin-left: 8px;
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(../images/tooltipIcon.svg) 0 0 no-repeat;
      background-size: 100%;

      &.touchover {
        background: url(../images/tooltipIcon-hover.svg) 0 0 no-repeat;
        background-size: 100%;
      }
    }

    .QuestionCircleOutlined {
      display: inline-block;
      margin: 8px 0 0 8px;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.16);
      width: 16px;
      height: 16px;
      border-radius: 16px;
      color: #ced3e1;
      font-size: 12px;
      font-family: AvenirNext-DemiBold;
      border: none;
      text-align: center;
      line-height: 16px;

      &:hover {
        opacity: 0.4;
      }
    }
  }

  .list {
    margin-top: 15px;
    padding: 0;
    background: #2d303c;
    border-radius: 12px;

    button {
      border-radius: 0 16px 16px 0;
      border: none;
      font-family: AvenirNext-Medium;
      padding: 0 15px;
      height: 24px;
      line-height: 24px;
      margin: 0 10px 0 -15px;
      font-size: 13px;
      color: #fff;
      background: rgba(91, 95, 115, 0.4);

      span {
        letter-spacing: 0px;
      }

      // &.collect-btn {
      //   color: #b0b8ff;
      //   background: #4455e6;
      // }
      // &.transfer-btn {
      //   color: #5cebc9;
      //   background: #1c8f74;
      // }
      clear: both;

      img {
        float: right;
        margin-top: 7px;
        margin-left: 5px;
      }
    }

    .item {
      &:hover {
        background: rgba($color: #363947, $alpha: 0.7);
      }

      padding: 20px 15px 0;

      &:nth-child(1) {
        &:hover {
          background: rgba($color: #363947, $alpha: 0.7);
          border-radius: 12px 12px 0 0;
        }
      }

      &:last-child {
        &:hover {
          background: rgba($color: #363947, $alpha: 0.7);
          border-radius: 0 0 12px 12px;
        }
      }
    }

    li {
      clear: both;
      height: 25px;
      line-height: 25px;

      &.amount {
        margin-top: 10px;
      }

      &.lh25 {
        line-height: 22px;

        .left {
          vertical-align: top;
        }
      }

      .left {
        font-size: 13px;
        opacity: 0.4;
        color: #ced3e1;
        font-family: AvenirNext-Regular;
      }

      .right {
        float: right;
        text-align: right;
        opacity: 0.7;
        font-family: AvenirNext-Regular;
        color: #ced3e1;
        font-size: 13px;

        a {
          color: #ced3e1;
        }

        &.time {
          font-size: 12px;
          color: #80838d;
        }

        .ellipsis_box {
          white-space: nowrap;
        }

        .ellipsis_box_start {
          display: inline-block;
        }

        .ellipsis_box_end {
          display: inline-block;
        }
      }

      .arrow {
        vertical-align: top;
        margin: 5px 0 0 20px;
        cursor: pointer;
      }

      &:first-child {
        margin-bottom: 5px;
      }

      &.amount {
        .left {
          font-size: 13px;
          color: #ced3e1;
          opacity: 1;
          font-family: AvenirNext-Medium;
        }

        .right {
          color: #ff5859;
          font-size: 14px;
          font-family: AvenirNext-DemiBold;
          opacity: 1;
          font-weight: 500;
        }

        .right-receive {
          color: #2ce1b7;
          font-family: AvenirNext-DemiBold;
          opacity: 1;
          font-weight: 500;
        }
      }
    }

    .divider {
      border-top: 1px solid rgba(27, 31, 38, 0.4);
      margin: 20px 0 0px -15px;
      width: calc(100% + 30px);
    }

    // .item:last-child {
    //   .divider {
    //     display: none;
    //   }
    // }
  }

  .pagination {
    margin-top: 12px;
    text-align: center;

    .ant-pagination {
      display: inline-block;
      vertical-align: top;
      color: #ced3e1;
      margin: 0 10px;
      line-height: 26px;

      .anticon {
        color: #ced3e1;
      }

      input {
        background: transparent;
        border: none;
        color: #ced3e1;
        display: inline-block;
        padding: 0;
        margin: 0;
        height: 26px;
        line-height: 26px;
        width: 26px;
        pointer-events: none;
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        background: rgba(54, 57, 71, 0.6);
        min-width: 26px;
        width: 26px;
        height: 26px;
        line-height: 26px;
      }
    }

    button {
      height: 26px;
      line-height: 26px;
      padding: 0 8px;
      border-radius: 5px;
      border: none;
      background: rgba(54, 57, 71, 0.6);
      color: #ced3e1;
      font-family: AvenirNext-Regular;
      font-size: 12px;
    }

    &.active {
      background: #4455e6;
    }
  }

  .notLogin {
    text-align: center;
    padding-bottom: 30px;
    background: rgba(51, 54, 67, 0.6);
    border-radius: 12px;
    margin-top: 16px;

    img {
      margin: 40px 0 14px;
    }

    p {
      font-family: AvenirNext-Regular;
      font-size: 13px;
      color: #80838d;
      line-height: 17px;
      margin-bottom: 0;
    }
  }
}
